import * as React from "react";

import { graphql } from "gatsby";

import "@components/index.module.css";
import "@components/globals.css";

import { Helmet } from "react-helmet";

import Header from "../../layouts/header";
import Hero from "../../layouts/hero/404";

import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";

import Image from "@images/svg/training_summary.inline.svg";

const TrainingSummaryProps = {
  headlines: {
    main: "Dziękujemy za zapisanie się na szkolenie!",
    sub: "Szczegółowe informacje zostaną wysłane na podany adres e-mail.",
  },
  headerButton: {
    label: "wróć do strony głównej",
    isDefault: true,
    isArrow: true,
    href: "/",
    theme: "theme9",
  },
  illustration: <Image />,
  additionalInfo: (
    <p>
      E-mail z potwierdzeniem nie dotarł?{" "}
      <a href="/kontakt">Skontaktuj się z nami.</a>
    </p>
  ),
};

const TrainingSummary = ({ data }) => {
  const menuItems = data.wpMenu.menuItems.nodes;

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang: "pl",
        }}
      >
        {/* <link rel="stylesheet" href="https://use.typekit.net/fyu0edh.css" /> */}
        <meta charset="UTF-8" />
        <title>Podziękowania - Mobipol</title>
      </Helmet>

      <Header links={menuItems} variant={"gray-light"} />
      <main>
        <Hero data={TrainingSummaryProps} variant={"third"} />
      </main>
    </>
  );
};

export default TrainingSummary;

export const query = graphql`
  {
    wp {
      generalSettings {
        url
        title
        description
        language
      }
    }
    wpMenu(name: { in: "Menu top" }) {
      name
      menuItems {
        nodes {
          id
          label
          order
          url
          path
          target
          parent {
            id
          }
          parentId
          connectedNode {
            node {
              ... on WpPage {
                id
                groupPage {
                  menuIcon {
                    altText
                    sourceUrl
                  }
                }
                pageOptions {
                  label
                  disableView
                  disableButtonFly
                }
              }
            }
          }
        }
      }
      parent {
        id
        ... on WpPage {
          id
        }
      }
      nodeType
    }
  }
`;

import * as React from "react";

import Section from "@components/section";
import SectionHeader from "@components/sectionHeader";

import * as styles from "./hero404.module.css";

const Hero404 = (props) => {
  const { headlines, headerButton, illustration, additionalInfo } = props.data;
  const { theme, variant } = props;

  return (
    <>
      <Section
        className={`${styles.hero} ${theme ? styles[`hero__${theme}`] : ""} ${
          variant ? styles[`hero__${variant}`] : ""
        }`}
        innerClassName={styles.hero__inner}
        theme={theme}
      >
        <SectionHeader
          headlines={headlines}
          button={headerButton}
          variant={"hero"}
          buttonTheme={headerButton.theme}
          className={styles.header}
          classNameSub={styles.sub}
          classNameButton={styles.button}
          isMainHeader
        />
        <div className={styles.illustration}>
          <figure className={styles.img__wrap}>{illustration}</figure>
        </div>
        {additionalInfo && <div className={styles.info}>{additionalInfo}</div>}
      </Section>
    </>
  );
};

export default Hero404;
